import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PrivacyPolicyContent from "../components/privacy-policy-content"



const PublicationsPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <PrivacyPolicyContent/>
  </Layout>
)

export default PublicationsPage