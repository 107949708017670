import React from 'react';
import { graphql, useStaticQuery} from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
  I18nextContext
} from "gatsby-plugin-react-i18next"

import styled from "styled-components"

import SectionHeader from './section-header';
import Avatar from '@material-ui/core/Avatar';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const Container = styled.div`
    width: 100%;
    // padding-top: 60px;
    text-align: center;
    // background: #ACD433;
    // background: #2c466b;
    // color: wheat;
    // margin-top: 15px;
    margin: 0 auto;
`

const Section = styled.div`
    
`


const ContentWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    background-color: ${props=>props.$backgroundColour};
    color: ${props=>props.$colour};
    padding-top: 60px;
    padding-bottom: 60px;
    
    @media screen and (max-width: 1220px) {
        padding-top: 90px;
    }
`

const ContentInner = styled.div`
    width: 73.306%;
    margin: 0 auto;
    
    @media screen and (max-width: 767px) {
        width: 100%;
    }
     
`

const Content = styled.div`
    margin: 0 auto;
    margin-top: 40px;
    max-width: 90%;
    
    p { 
        
        font-size: 20px;
        line-height: 1.4;
        margin-top: 14px;
        font-weight: 400;
        white-space: pre-wrap;
        margin-bottom: 14px;
        text-align: left;
        
        @media screen and (max-width: 767px) {
            font-size: 18px;
         }
    }
`

const AvatarsContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    
    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }
`

const AvatarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    margin: 25px;
    
`

const StyledAvatar = styled(Avatar)`
    width: 160px;
    height: 160px;
    margin-bottom: 20px;
    cursor: pointer;
    
    @media screen and (max-width: 767px) {
        margin-bottom: 0;
    }
`

const Name = styled.h4`
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
`

const Titles = styled.p`
    &.align-center {
        // font-size: 20px;
        text-align: center;
    }
`

const LinkButton = styled(Button)`
    color: #fff;
    background-color: #2c466b;
    width: 160px;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    font-family: century-gothic,sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
    
    &:hover {
        // color: white;
        color: #ff8000;
        background-color: #2c466b;
        text-stroke: 1px;
        -webkit-text-stroke: 1px;
        -webkit-text-stroke-color: #ff8000;
        -webkit-text-stroke-width: 1px;
    }
`

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(44, 70, 107, 0.3)',
    textAlign: 'left',
    // fontSize: 18,
    '& p': {
        textAlign: 'left'
    }
  },
  paper: {
    position: 'relative',
    maxWidth: 750,
    maxHeight: '80vh',
    overflowY: 'scroll',
    marginTop: 55,
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    
    '&::-webkit-scrollbar': {
        display: 'none'
    },

    '&:focus': {
        outline: 0,
    },
  },
  
  modalHeader: {
      display: 'flex',
  },
  
  modalHeaderInner: {
        marginLeft: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
  },
  
  modalTitle: {
      '&&': {
          textAlign: 'left',
      }
  },
  closeIcon: {
      position: 'absolute',
      right: 16,
      cursor: 'pointer',
      
      '&:hover': {
          color: '#ff8000',
      }
      // right: 0,
  }
  
}));


const AboutUsContent = () => {
    const {t} = useI18next();
    const context = React.useContext(I18nextContext);
    const language = context.language;
    
    const classes = useStyles();
    
  
    const data = useStaticQuery(graphql`
    query {
 
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(privacy-policy)/"  }}) {
            
            edges {
                node {
                    frontmatter {
                        title
                        language
                  }
                  html
                  id
                }
            }
        }
    }
  `)
  
  let edges = data.allMarkdownRemark.edges
  let edgesFilteredByLanguage = edges.filter(edge=>edge.node.frontmatter.language == language)
  
  React.useEffect(()=>{
      console.log(edgesFilteredByLanguage)
  }, [])
  
    return (
        
        <Container>
            <ContentWrapper id="" >
                    <SectionHeader id="section-header" title={t("PRIVACY-POLICY.PRIVACY-POLICY")} />
                    <Content>
                        <ContentInner>
                            {
                                edgesFilteredByLanguage.map((policy, index)=>{
                                    return (
                                        <div key={index} dangerouslySetInnerHTML={{__html: policy.node.html}} />
                                    )
                                })
                            
                            }
                        </ContentInner>
                    </Content>
            </ContentWrapper>
        </Container>
        
    
    )
}

export default AboutUsContent